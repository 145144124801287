body {
  font-family: 'Varela Round', sans-serif;
}

a {
  color: inherit;
}

h1, h2, h3, h4, h5, h6, p, a, {
  &:first-of-type {
    margin-top: 0;
  }
}

ul {
  padding-left: 0;
  li {
    list-style: none;
  }
}

.t-headline {
  font-size: 1.4rem;
  line-height: 1.6;
  color: c(grey);
  max-width: 20rem;
  span {
    color: #7f969e;
    display: inline-block;
    margin: 0 0.6em;
    transform-origin: 50% 80%;
    transform: scale(1.3) rotate(-3deg);
  }
}

.t-body {
  font-size: 0.75rem;
  line-height: 1.6;
  max-width: 20rem;
}

[data-disco-mode="1"]{
  .t-headline {
    color: #fff;
    span {
      color: hsl(319, 100%, 59%);
      text-shadow: 0 0 5px hsl(319, 50%, 70%);
    }
  }
  .t-body {
    color: #fff;
  }
  .content p {
    color: #fff;
    text-decoration-color: #ff2dbd;
    text-underline-position: below;
  }
}