.nav {
  display: none;
  width: 100%;
  position: relative;

  @include tablet {
    display: block;
  }

  @include nav-toggled {
    display: block;
  }
}

.nav__container {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0;
  text-align: center;
  list-style-type: none;
}
.nav__container--sm {
  @include tablet {
    display: none;
  }
}

.nav__item {
  display: inline-block;
  color: $base;
  @include disco-mode {
    color: #ccc;
  }
  position: relative;
  padding: $p/4 0;
  margin: 0 $p*1.2;
  @include nav-toggled {
    width: 100%;
    margin: 0;
    padding: $p/2 0;
  }
  &.active {
    font-weight: bold;
    color: $black;
    @include disco-mode {
      color: #fff;
    }
    &:after {
      position: absolute;
      display: block;
      content: "";
      border-bottom: 2px solid c(yellow, logo);
      width: 100%;
      margin-top: 0.25rem;
      @include nav-toggled {
        width: 50%;
        left: 25%;
      }
    }
  }
}

.nav__link {
  display: block;
  text-decoration: none;

  .nav__item:last-child & {
    padding-right: 0;
  }
}
