// --- variables ---

// sizing
$rem: 16px;
$p:   1.5rem;

$header: 5rem;

// breakpoints
$breakpoint-first: tablet;
$breakpoints: (
  tablet:  640,
  desktop: 1100,
  massive: 1400,
);

// colours
$black:  #000;
$base:   #333;
$bg:     #fff;
$accent: #ccc;

// transitions
$transition: 0.3s ease;

// etc
$max: 99999px;


// --- mixins & functions ---

// breakpoints

@function bp($size) {
  @return map-get($breakpoints, $size) * 1px;
}
@mixin mq($size) {
  @include breakpoint(bp($size)) { @content; }
}

@mixin mobile {
  @include breakpoint(max-width (bp($breakpoint-first) - 1px)) {
    @content;
  }
}
@mixin tablet {
  @include breakpoint(bp(tablet))  { @content; }
}
@mixin desktop {
  @include breakpoint(bp(desktop)) { @content; }
}
@mixin massive {
  @include breakpoint(bp(massive)) { @content; }
}

@mixin container {
  @include mq($breakpoint-first) {
    display: flex;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  @include mobile {
    width: 90%;
    margin-left: 5%;
  }
}


// transitions

@mixin animate($props...) {
  $transitions: ();
  @each $prop in $props {
    $transitions: append($transitions, $prop $transition, comma);
  }

  transition: $transitions;
}

@mixin animateSVGFill($colour, $duration, $delay) {
  $colours: (
    red: c(red),
    yellow: c(yellow, alt),
    turquoise: c(turquoise),
    white: #ebebeb,
    blue: c(blue, alt),
    purple: c(purple),
    altred: c(red, alt)
  );
  @each $colour,  $value in $colours {
    @keyframes #{$colour} {
      0% { fill: rgba($value, 0.2); }
      50% { fill: rgba($value, 0.9); }
      100% { fill: rgba($value, 0.2); }
    }
  }
  animation-name: $colour;
  animation-duration: $duration;
  animation-timing-function: ease;
  animation-delay: $delay;
  // animation-direction: alternate;
  animation-play-state: running;
  animation-iteration-count: infinite;
}

// visibility

@mixin nav-toggled {
  html[data-nav-toggled="1"] & {
    @include mobile {
      @content;
    }
  }
}

@mixin disco-mode {
  html[data-disco-mode="1"] & {
      @content;
  }
}

@mixin sr-only {
  font-size: 1px;
  position: absolute;
  top: -$max;
  left: -$max;
}
