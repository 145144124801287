input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
  height: 38px;
  padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
  background-color: rgba(c(blue, alt), 0.5);
  border: 1px solid darken(c(grey, light), 6%);
  border-radius: 2px;
  box-shadow: none;
  box-sizing: border-box;
  width: 100%
}

/* Removes awkward default styles on some inputs for iOS */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

textarea {
  min-height: 100px;
  padding-top: 6px;
  padding-bottom: 6px;
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  border: 1px solid c(orange);
  outline: 0;
}

label,
legend {
  display: block;
  margin-bottom: .5rem;
  color: $base;
}

fieldset {
  padding: 0;
  border-width: 0;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
}

label > .label-body {
  display: inline-block;
  margin-left: .5rem;
}

input[type="radio"] {
  opacity: 0;
  width: 0;
  height: 0;
}
input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}

.form__radio__label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  transition: color 0.2s ease;
  svg {
    position: relative;
    width: 38px;
    height: 38px;
    .form__radio-label--centre {
      opacity: 0;
      transition: opacity 0.2s ease;
    }
  }
  span {
    margin-left: 10px;
  }
  &:hover {
    color: c(blue);
    svg .form__radio-label--centre {
      opacity: 0.7;
    }
  }
}

// input[type="radio"]:active ~ label {
//   svg .form__radio-label--centre {
//     opacity: 1;
//   }
// }

input[type="radio"]:checked ~ label {
  color: c(blue);
  svg .form__radio-label--centre {
    opacity: 1;
  }
}
input[type="checkbox"]:checked ~ label {
  color: c(blue);
  svg .form__radio-label--centre {
    opacity: 1;
  }
}

.form {
  form {
    display: flex;
    @include mobile {
      flex-direction: column;
    }
  }
  .form__item {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 2rem;
    label span {
      display: block;
      font-size: 0.8em;
      max-width: 70%;
    }
  }
}

.button {
  padding: 0.5rem 0.8rem;
  background-color: c(blue, alt);
  border-radius: 2px;
  border: 2px solid darken(c(blue, alt), 6%);
  box-shadow: inset 0 -2px 10px 2px darken(c(blue, alt), 2%);
  color: #fff;
}
