.main {
  min-height: calc(100vh - #{$header * 2});
  @include tablet {
    min-height: 15rem;
  }

  @include nav-toggled {
    display: none;
  }
}

.content {
  @include container();
  padding: 2rem 0 3rem 0;
  max-width: 46rem;
  @include mobile {
    position: relative;
    width: 86%;
    margin-left: 7%;
  }
}
