html {
  font-size: $rem;
  color: $base;
  background-color: $bg;
}

button {
  border: 0;
  background-color: transparent;
}
